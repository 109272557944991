import React from "react";
import coverBgCustomer from "../images/coverBgCustomer.png";
import { Link } from "react-router-dom";

function CustomerCover() {
  return (
    <>
      <div id="cover">
        <div
          id="customercoverBack"
          style={{
            background: `url(${coverBgCustomer}) bottom center`,

          }}
          className="d-flex flex-column align-items-center"
        ></div>
        <div
          id="customercoverShadow"
        >
          <div className="d-flex flex-column align-items-center" id="customercover">
            <h1 className="text-center mt-4 fw-bold ">
              On Demand <br />Home Chef Service
            </h1>
            <h5 className="text-center mt-3 px-5 px-md-0">
              Book Your Home Chef to Cook/Meal Prep <br className="d-none d-md-block" /> Upto 4 Meals or Less, Each for Serving Size of Upto 5 People or Less, for Fixed Price of
            </h5>
            <h4 className="text-center fs-3 fw-bold mb-3">
              $109
            </h4>
            <div className="text-center mt-3">
              <Link to="/sign-up" className="btn-orange mx-2">
                Sign Up
              </Link>
              <Link to={
                localStorage.getItem("token") && localStorage.getItem("chef_id") ? "/chef/order-list"
                  : localStorage.getItem("token") && localStorage.getItem("role") !== "2" && localStorage.getItem("logged_user_id")
                    ? "/chef-list" : "/sign-in"} className="btn-white mx-2">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerCover;
