import DOMPurify from "dompurify";

const PurifyHtml = (content) =>{
    const TEMPORARY_ATTRIBUTE = 'data-temp-href-target'

    DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
        if (node.tagName === 'A') {
            if (!node.hasAttribute('target')) {
                node.setAttribute('target', '_self')
            }
            if (node.hasAttribute('target')) {
                node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'))
            }
        }

        if (node.tagName === 'IFRAME') {
            if (!node.hasAttribute('allowfullscreen')) {
                node.setAttribute('allowfullscreen', '')
            } 
        }
    })
    
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
            node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE))
            node.removeAttribute(TEMPORARY_ATTRIBUTE)
            if (node.getAttribute('target') === '_blank') {
                node.setAttribute('rel', 'noopener')
            }
        }

        if (node.tagName === 'IFRAME') {
            if (!node.hasAttribute('allowfullscreen')) {
                node.setAttribute('allowfullscreen', '')
            } 
        }
     
    })

    return DOMPurify.sanitize(content,  { ADD_TAGS: ["iframe"] ,ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] } )
}

export default PurifyHtml;