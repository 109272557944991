import React, { useEffect, useState } from 'react';
import image from "../images/logo.png";

function PopUp(props) {
    // console.log(props);

    const [url, setUrl] = useState(null);
    const [loadNow, setLoadNow] = useState(false);

    useEffect(() => {
        // getPdf();
    }, []);

    const getPdf = () => {
        setLoadNow(false);
        fetch(`${process.env.REACT_APP_BASE_URL}user/getChefMenu`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ user_id: localStorage.getItem("chef_id").toString() }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                // console.log(data);
                if (data.menu_path) {
                    let pdfUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${data.menu_path}`;
                    setUrl(pdfUrl);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                setLoadNow(true);
            });
    };

    var NoMenuPlaceholder = "Oops! It seems the chef is still busy crafting the menu. Please check back later for updates!";

    return (
        <div className="modal fade" style={{ textAlign: 'initial' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-scrollable modal-lg">
                <div className="modal-content" style={{ minHeight: '80%' }}>
                    <div className="modal-header">
                        <img className='img-fluid ms-md-2' style={{ maxWidth: '100px', height: 'auto' }} src={image} alt="brand-logo" />
                        <h6 className='m-0 ms-3 fw-bolder'>{`Chef ${props.chef_name ?? 'Menu'}`}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {props.img ?
                        <div className="modal-body d-flex justify-content-center">
                            <img src={props.img} style={{ height: "auto", width: "100%" }} alt="grocery" />
                        </div>
                        :
                        <div className="modal-body ps-md-4 py-3">
                            <div dangerouslySetInnerHTML={{ __html: props.rich_text_menu ?? NoMenuPlaceholder }}></div>
                            {/* {props.pdf ?
                                !loadNow ? <p>Loading...</p> :
                                    <object data={url} type="application/pdf" height="550px" width="100%">
                                        <iframe src={url} height="550px" width="100%"></iframe>
                                    </object>
                                } 
                            */}
                        </div>
                    }

                    <div className="modal-footer ps-md-3">
                        <p style={{ fontSize: '.8rem' }}>
                            Book your home chef with Chef Republic and let us do the meal prepping for you. Savor the home cooked meals prepped specific to your culinary and dietary preferences.
                        </p>
                    </div>

                </div>
            </div>
        </div >
    );
}


export default PopUp;

