import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import LoggedNav from "../components/LoggedNav";
import PopUp from "../components/Popup";
import Spinner from "./BePatient";
// import { Rating } from "react-simple-star-rating";
import Rating from '@mui/material/Rating';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from "react-router-dom";
import profile from "../images/Vector.jpg";


function BrowseChefMobile(props) {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  function extractValue(arr, prop) {

    // extract value from property
    let extractedValue = arr.map(item => item[prop]);

    return extractedValue;

  }
  let navigate = useNavigate();

  const updateChefs = async () => {
    setLoading(true);
    let data = await fetch(
      `${process.env.REACT_APP_BASE_URL}user/loggedin_chef_detail`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    let parsedData = await data.json();
    setDetails(parsedData.data);
    setLoading(false);
  };
  let role = localStorage.getItem("role");
  let token = localStorage.getItem("token");
  useEffect(() => {
    var title = "Profile | CHEF | CHEF REPUBLIC";
    var desc = "View Chef Profile in Detail including the menu, available timings, learn about the Chef, get to know the chef by viewing their pics";
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title);
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc);
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title);
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc);
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title);
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc);

    if (role === null || role !== "2") {
      navigate("/chef/sign-in");
      window.location.reload();
    }
    if (token === null || !token) {
      navigate("/chef/sign-in");
      window.location.reload();
    }
    updateChefs();
    // eslint-disable-next-line
  }, []);
  function navigateEdit() {
    navigate("/chef/profile/edit");
  }
  function logout() {
    localStorage.clear();
    navigate("/chef");
  }
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <LoggedNav customer={props.customer} />
          <div className="fixBox">
            <button
              onClick={navigateEdit}
              type="button"
              className="btn btn-orange rounded-pill mb-3 "
              style={{ width: "70%" }}
            >
              Edit Profile
            </button>
            <button
              onClick={() => { navigate("/chef/order-list"); }}
              type="button"
              className="btn btn-orange rounded-pill mb-3 "
              style={{ width: "70%" }}
            >
              Order List
            </button>
            <button
              onClick={logout}
              type="button"
              className="btn btn-orange rounded-pill mb-3 "
              style={{ width: "70%" }}
            >
              Logout
            </button>
          </div>

          <div className="image-box">
            <OwlCarousel items={1} center={true} mouseDrag={true} touchDrag={true} autoplay={true} autoplayHoverPause={true} dotsEach={true} className="owl-theme my-4" loop>
              <div className="d-flex justify-content-center align-items-center"><img
                src={details[0].chef_profile_image}
                alt=""
                width="auto"
                style={{ maxHeight: "280px", width: "auto", maxWidth: "100%", }}
              /></div>
              {details[0].chef_images.map((element, index) => {
                return <div className="d-flex justify-content-center align-items-center"><img
                  src={element}
                  alt=""
                  width="auto"
                  style={{ maxHeight: "280px", width: "auto", maxWidth: "100%", }}
                /></div>;
              })}
            </OwlCarousel>
          </div>
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-8">
                <h3 className="fw-bolder">{details[0].chef_name}, {details[0].chef_sex}</h3>
                <p>{details[0].chef_city}</p>
              </div>
              <div className="col-4 text-end">
                {/* <Rating
                  allowFraction={true}
                  initialValue={details[0].chef_rating}
                  size={18}
                  allowHover={false}
                  readonly={true}
                /> */}
                <Rating name="half-rating-read" defaultValue={Number(details[0].chef_rating)} precision={0.5} readOnly />

                <p className="review-count">
                  {details[0].chef_total_number_of_reviews} reviews
                </p>
              </div>
            </div>

            <hr />
            <div className="message_box py-1 text-center">
              <h5 className="text-center" style={{ fontWeight: 700 }}>I can cook</h5>
              <p className="mt-1 text-break">{details[0].chef_categories ? extractValue(details[0].chef_categories, "category_name").join(", ") : ""}</p>
              <button
                type="button"
                className="btn-orange"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                View my menu
              </button>
              <PopUp chef_name={details[0].chef_name} rich_text_menu={details[0].rich_text_menu} pdf={details[0].chef_menu} />
            </div>
            <hr />
            <div className=" my-2 py-2 ">
              <h5 className="my-2 pb-2 text-center" style={{ fontWeight: 700 }}>My Availability</h5>
              <div className="row p-3">
                {details[0].chef_availability && details[0].chef_availability.monday.length > 0 ? <>
                  <div className="col-3 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontWeight: 700 }}>Mon:</p>
                  </div>
                  <div className="col-9 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontSize: "1rem", letterSpacing: -0.1 }}>{details[0].chef_availability.monday.join("; ")}</p>
                  </div>
                </> : null}
                {details[0].chef_availability && details[0].chef_availability.tuesday.length > 0 ? <>
                  <div className="col-3 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontWeight: 700 }}>Tue:</p>
                  </div>
                  <div className="col-9 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontSize: "1rem", letterSpacing: -0.1 }}>{details[0].chef_availability.tuesday.join("; ")}</p>
                  </div>
                </> : null}
                {details[0].chef_availability && details[0].chef_availability.wednesday.length > 0 ? <>
                  <div className="col-3 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontWeight: 700 }}>Wed:</p>
                  </div>
                  <div className="col-9 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontSize: "1rem", letterSpacing: -0.1 }}>{details[0].chef_availability.wednesday.join("; ")}</p>
                  </div>
                </> : null}
                {details[0].chef_availability && details[0].chef_availability.thursday.length > 0 ? <>
                  <div className="col-3 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontWeight: 700 }}>Thu:</p>
                  </div>
                  <div className="col-9 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontSize: "1rem", letterSpacing: -0.1 }}>{details[0].chef_availability.thursday.join("; ")}</p>
                  </div>
                </> : null}
                {details[0].chef_availability && details[0].chef_availability.friday.length > 0 ? <>
                  <div className="col-3 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontWeight: 700 }}>Fri:</p>
                  </div>
                  <div className="col-9 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontSize: "1rem", letterSpacing: -0.1 }}>{details[0].chef_availability.friday.join("; ")}</p>
                  </div>
                </> : null}
                {details[0].chef_availability && details[0].chef_availability.saturday.length > 0 ? <>
                  <div className="col-3 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontWeight: 700 }}>Sat:</p>
                  </div>
                  <div className="col-9 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontSize: "1rem", letterSpacing: -0.1 }}>{details[0].chef_availability.saturday.join("; ")}</p>
                  </div>
                </> : null}
                {details[0].chef_availability && details[0].chef_availability.sunday.length > 0 ? <>
                  <div className="col-3 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontWeight: 700 }}>Sun:</p>
                  </div>
                  <div className="col-9 text-start p-0 my-2">
                    <p className="p-0 m-0" style={{ fontSize: "1rem", letterSpacing: -0.1 }}>{details[0].chef_availability.sunday.join("; ")}</p>
                  </div>
                </> : null}
              </div>
            </div>
            <hr />
            <div className="message_box my-4 text-center ">
              <h5 className="my-2 py-2" style={{ fontWeight: 700 }}>
                {" "}
                About me
              </h5>
              <p className="my-2 text-justify">{details[0].chef_description}</p>
            </div>
            <hr />
            <div className="row mt-3 text-center">
              <div className="col">
                <h5 className="text-center" style={{ fontWeight: 700 }}>
                  Client Reviews
                </h5>
              </div>

              {details[0].chef_reviews && details[0].chef_reviews.length > 0 ? <OwlCarousel items={1} mouseDrag={true} touchDrag={true} autoplay={true} autoplayHoverPause={true} dotsEach={true} className="owl-theme my-4" loop>
                {details[0].chef_reviews.map((element, index) => {
                  return <div className="item" key={index}>
                    {/* <Rating
                      allowFraction={true}
                      initialValue={element.rating}
                      size={24}
                      allowHover={false}
                      readonly={true}
                      style={{ display: "flex", margin: "10px 0", alignItems: "center" }}
                    /> */}
                    <Rating name="half-rating-read" defaultValue={Number(element.rating)} precision={0.5} readOnly />
                    <p className="mt-2 text-wrap text-break" style={{ fontSize: "0.9rem" }}>{element.review_content}</p>
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="ratings text-warning h4 my-3">
                        <img src={element.user_image ? element.user_image : profile} className="rounded-pill" style={{ width: "35px", height: "35px" }} alt="" />
                      </div>
                      <p className="review-count" style={{ marginLeft: "15px", marginBottom: "1px", fontSize: "0.9rem" }}>{element.reviewed_by}</p>
                    </div>
                  </div>;
                })

                }
              </OwlCarousel> : <p className="my-2 text-justify">No Review Yet</p>}
            </div>
          </div>

          <Footer top={1} />
        </>
      )}
    </>
  );
}

export default BrowseChefMobile;
