import React from "react";
import { isMobile } from 'react-device-detect';

function Benefits() {
  return (
    <div className="benefits row m-0 p-0" id="Benefits">
      <div id="title-mobile" className="mb-3">Benefits for you</div>
      <div className="col-md-6 p-4 d-flex flex-column text-center text-md-end">
        <h3>Become</h3>
        <h3 id="benefitsPercentage">100%</h3>
        <h3>Stress Free and Focus on Wellness </h3>
      </div>
      <div className="col-md-6 p-4 d-flex flex-column ">
        <h6 id="title-desktop">Benefits for you</h6>
        <h6
          className="lh-base"
          id="benefits-desc"
        >
          With home chef prepping meals for you, you not only save on costs and get fresh home cooked meals cooked per your preference in taste, but you also save a lot more time on meal prepping/planning, cooking, cleaning dishes and kitchen area. You can now use your valuable time to spend with your friends and family and live a stress free life.
        </h6>
      </div>
    </div >
  );
}

export default Benefits;
