import React from "react";
import { Rating } from 'react-simple-star-rating';
import profile from "../images/Vector.jpg";

function FaqItem(props) {

  var noImageAvailable = props.element.image === 'https://backend.chefrepublic.us/';

  return (
    <div className="item vstack align-items-center p-3">
      <div className="d-flex align-items-center mb-2">
        <img src={noImageAvailable ? profile : props.element.image} style={{ borderRadius: "50%", height: "30px", width: "30px" }} alt="" />
        <h6 className="ms-3 mb-0">{props.element.name}</h6>
      </div>

      <Rating
        allowFraction={true}
        initialValue={props.element.rating}
        size={22}
        allowHover={false}
        readonly={true}
      />
      <h6 className='text-center mt-3'>{props.element.description}</h6>

    </div>
  );
}

export default FaqItem;
